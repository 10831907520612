<template>
  <div>
    <iframe
      :src='frame'
      width='100%'
      height='1200px'
      frameborder='0'
    ></iframe>
  </div>
</template>
<script src='https://cdn.jsdelivr.net/jsbarcode/3.6.0/JsBarcode.all.min.js'></script>
<script>
import { h, ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, printDate } from '@/plugins/filters'
import 'jspdf-barcode'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const frame = ref('')
    const fontBody = ref(25)
    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    prints.printsLabLabel({
      opd_id: route.value.query.id.split(','),
      code: route.value.query.code ? route.value.query.code : '',
      amount: 1,
      lang: i18n.locale,
    }).then(res => {
      savePDF(res)
    })

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'landscape',
        format: [2.5, 1.25],
        unit: 'in',
      })
      doc.setProperties({
        title: `LAB LABEL - ${data[0].print_date}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'bold')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      const spacer = 0.4
      let total = 0.2
 //total += spacer + 0.1
      for (let i = 0; i < data.length; i++) {
        let customer_fullname = (data[i].customer_fullname.replaceAll('\r', '') + ' ' + data[i].customer_gender.replaceAll('\r', '') + ' ' + data[i].customer_age).replaceAll('\r', '')
        let shop_name = (data[i].shop_name.replaceAll('\r', '') + ' ' + data[i].shop_tel).replaceAll('\r', '')
        doc.setFontSize(14)
        doc.text(customer_fullname.replaceAll('\r', '') , 0.16, 0.19, null, null, )
        doc.setFont('TH-Niramit', 'bold')
        doc.text(data[i].opdchecking_code.replaceAll('\r', ''), 0.16, 0.32, null, null, )
        doc.setFont('TH-Niramit', 'bold')
        doc.text(data[i].opdchecklist_name.replaceAll('\r', ''), 0.16, 0.45, null, null, )
        doc.text(route.value.query.code ? route.value.query.code : data[i].code, 0.16, 0.58, null, null, )
        doc.text(shop_name, 0.16, 0.70, null, null, )
        doc.text(`${data[i].customer_id.replaceAll('\r', '')} ${printDate(data[i].print_date)}`, 0.16, 0.82, null, null, )
        doc.addImage(data[i].opdchecking_code_base64, 'JPEG', -0.001, 0.85, 2.1, 0.25)
        total += spacer + 0.1
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring')
    }

    return { savePDF, frame }
  },
}
</script>
